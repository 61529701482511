import styles from './CourseModulePage.module.css';
import {
  useParams,
  Navigate,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import {
  CourseModule,
  CourseModuleLoader,
  ModuleSideBar,
  ModuleSideBarLoader,
} from '../../organisms';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { getCurrentAppLanguage } from '../../i18n';
import {
  getNextModule,
  getPercentageComplete,
} from '../../utils/interfaces/academy-api/UserCourseResult.helper';
import { UserModuleResult } from '../../utils/interfaces';
import { logoutRequest } from '../../utils/api/legacy-api/auth-api/auth';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';
import { logoutUser } from '../../redux/Login/LoginSlice/actionCreators';
import { setLoading } from '../../redux/UISlice';
import { selectUser } from '../../redux/Login/LoginSlice';
import {
  selectCourse,
  selectCourseError,
  selectCourseIsLoading,
} from '../../redux/CourseSlice';
import {
  fetchCourseById,
  updateModuleCompleted,
} from '../../redux/CourseSlice/actionCreators';
import { AppLanguage } from '@mono/common';
import { FloatingButton } from '../../atoms/FloatingButton';
import { Icons } from '../../assets/icons';
import useWindowDimensions from '../../utils/hooks/useWindowDimensions';
import { AcademyLogo } from '../../molecules';
import { shuffle } from 'lodash';

const ANIMATION_DURATION = 500;

export const CourseModulePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const { i18n } = useTranslation();
  const getNextUrlState = () => ({
    state: { ...(location.state ?? {}), isFirstOpened: undefined },
  });

  const [appLanguage, setAppLanguage] = useState(getCurrentAppLanguage());
  const [module, setModule] = useState<UserModuleResult | undefined>(undefined);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isMobileSidebarDisplayed, setIsMobileSidebarDisplayed] =
    useState(false);
  const [percentageOfModulesCompleted, setPercentageOfModulesCompleted] =
    useState<number>(0);
  const [haveSlideOutClass, setHaveSlideOutClass] = useState(false);
  const { courseId, moduleId } = useParams<{
    courseId: string;
    moduleId: string;
  }>();

  const course = useSelector(selectCourse);
  const courseLoading = useSelector(selectCourseIsLoading);
  const courseError = useSelector(selectCourseError);
  const { width } = useWindowDimensions();

  const nextModule = useMemo(() => {
    if (!course || !module) {
      return undefined;
    }
    return getNextModule(course, module._id);
  }, [course, moduleId]);
  const isLastModule = !nextModule;

  useEffect(() => {
    if (courseId) {
      dispatch(fetchCourseById(courseId));
    }
  }, [courseId, moduleId, dispatch]);

  useEffect(() => {
    if (!course || courseId !== course._id) return;

    const moduleFromCourse = course.modules.find(
      (m: UserModuleResult) => m._id === moduleId,
    );

    if (!moduleFromCourse || !course.enabled) {
      navigate('/404');
      return;
    }

    setModule((prevModule) => {
      if (prevModule && moduleId === prevModule._id) {
        return { ...prevModule, completed: moduleFromCourse.completed };
      }
      return moduleFromCourse;
    });

    setPercentageOfModulesCompleted(getPercentageComplete(course));
  }, [course, moduleId]);

  const user = useSelector(selectUser);

  useEffect(() => {
    setAppLanguage(i18n.language as AppLanguage);
  }, [i18n.language]);

  if (!moduleId || !courseId || courseError) {
    return <Navigate to="/404" replace />;
  }

  const launchCompletedTransition = () => {
    setIsComplete(true);
  };

  const onContinue = async () => {
    await onGoToNextModule();
  };

  const onGoBack = () => {
    const prevCoursesPageUrl = location.state?.from || '/';
    navigate(prevCoursesPageUrl);
    return;
  };

  const doSaveProgress = async () => {
    if (module?.completed) {
      return;
    }

    try {
      dispatch(updateModuleCompleted(courseId, moduleId));
    } catch (error) {
      console.error('Failed to save user module', error);
    }
  };

  const onGoToNextModule = async () => {
    if (!course) {
      return;
    }

    if (!nextModule) {
      launchCompletedTransition();
      await asyncTimeout(350);
    }

    navigate(
      nextModule
        ? `/courses/${courseId}/modules/${nextModule._id}`
        : `/courses/${courseId}/completed`,
      getNextUrlState(),
    );
  };

  const doLogout = async () => {
    dispatch(setLoading(true));
    await logoutRequest();
    dispatch(logoutUser());
    dispatch(setLoading(false));
    window.location.reload();
  };

  const doNavigateToModule = (moduleId: string) => {
    if (width < 768) {
      toggleMobileSidebar();
    }
    navigate(`/courses/${courseId}/modules/${moduleId}`, getNextUrlState());
  };

  const toggleMobileSidebar = () => {
    const newState = !isMobileSidebarDisplayed;

    if (!newState) {
      setHaveSlideOutClass(true);
      setTimeout(() => {
        setIsMobileSidebarDisplayed(newState);
        setHaveSlideOutClass(false);
      }, ANIMATION_DURATION);
    } else {
      setIsMobileSidebarDisplayed(newState);
    }
  };

  const onOverlayClick = () => {
    if (!isMobileSidebarDisplayed) return;
    toggleMobileSidebar();
  };

  const shuffleQuestionsAlternatives = () => {
    if (!module) return;

    const questions = module.questions.map((question) => ({
      ...question,
      multipleChoiceAlternatives: shuffle([
        ...question.multipleChoiceAlternatives,
      ]),
    }));

    setModule({ ...module, questions });
  };

  return (
    <div className={styles.container} data-testid="course-module-page">
      <div
        className={`${styles.displayedSidebarOverlay} ${isMobileSidebarDisplayed && styles.openOverlay} ${!isMobileSidebarDisplayed && `${styles.overlayHidden}`} ${haveSlideOutClass && `${styles.closeOverlay}`}`}
        onClick={onOverlayClick}
      ></div>
      <div
        className={`${styles.sidebar} ${isMobileSidebarDisplayed && styles.openSidebar} ${haveSlideOutClass && styles.closeSidebar}`}
      >
        {course && course._id === courseId ? (
          <ModuleSideBar
            userName={user?.displayName ?? 'Student'}
            userPhoto={user?.photoFileName ?? null}
            course={course}
            activeModuleId={moduleId}
            doLogout={doLogout}
            doNavigateToModule={doNavigateToModule}
          />
        ) : (
          <ModuleSideBarLoader />
        )}
      </div>
      <div className={styles.courseModule}>
        <div
          className={`${styles.topContainer} ${isMobileSidebarDisplayed && styles.hiddenTopContainer}`}
        >
          <div className={styles.progressBar}>
            <div
              className={styles.progress}
              style={{ width: `${percentageOfModulesCompleted}%` }}
            />
          </div>
          <div className={styles.mobileHeader}>
            <div className={styles.mobileHeaderLogo}>
              <AcademyLogo type="dark" goHomeOnClick />
            </div>
            <FloatingButton
              onClick={toggleMobileSidebar}
              content={
                isMobileSidebarDisplayed ? (
                  <Icons.Cross />
                ) : (
                  <Icons.HamburgerMenu />
                )
              }
            />
          </div>
        </div>
        <FloatingButton
          onClick={toggleMobileSidebar}
          content={
            isMobileSidebarDisplayed ? <Icons.Cross /> : <Icons.HamburgerMenu />
          }
          className={styles.menuBtn}
        />
        {!courseLoading && module ? (
          <CourseModule
            module={module!}
            isLastModule={isLastModule}
            appLanguage={appLanguage}
            doSaveProgress={doSaveProgress}
            onContinue={onContinue}
            onGoBack={onGoBack}
            shuffleQuestionsAlternatives={shuffleQuestionsAlternatives}
          />
        ) : (
          <CourseModuleLoader />
        )}
      </div>
      <div
        className={`${styles.curtain} ${isComplete ? styles.animationTransitionComplete : ''}`}
      />
    </div>
  );
};

const asyncTimeout = (duration: number = 0) =>
  new Promise((resolve) => setTimeout(resolve, duration));
